<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :selectable="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(status)="data">
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.status)"
        >
          {{ getTypes(data.item.status) }}
        </b-badge>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/gifts',
      addType: 'Add gift',
      addComponentName: 'add-gifts',
      editComponent: 'edit-gifts',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: false },
        { key: 'image', label: 'Image' },
        { key: 'name', label: 'Name' },
        { key: 'description_en', label: 'Description' },
        { key: 'status', label: 'Status' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
  },
}
</script>

<style>

</style>
